var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('ais-configure',{attrs:{"facetFilters":_vm.aisFilters,"facets":['title'],"query":_vm.aisQuery}}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(searchState){return [_c('div',{staticClass:"artist-page__content"},[_c('div',{staticClass:"filter-icon__container"},[_c('a',{staticClass:"filter-link",class:{ active: _vm.showFilter },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onFilterClick($event)}}},[_c('img',{staticClass:"filter-icon",attrs:{"src":_vm.filterIcon,"alt":"Filter"}})])]),_c('router-link',{staticClass:"back",attrs:{"to":"/"}},[_c('img',{staticClass:"desktop_form_close",attrs:{"src":require("@/assets/img/close.svg"),"alt":"close"}})]),_c('div',{staticClass:"group"},[_c('label',[_c('strong',[_vm._v("Artist:")])]),_c('div',{ref:"headlineContainer",staticClass:"headline__container"},[_c('BaseHeadline',{ref:"headline",attrs:{"maxLength":"55","text":_vm.$route.params.artist_name}}),(_vm.screen.width > 767)?_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return _c('div',{staticClass:"filter-input__container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(currentRefinement),expression:"currentRefinement"}],staticClass:"filter-input",class:{ active: _vm.showFilter },attrs:{"placeholder":"type to filter...","type":"search"},domProps:{"value":(currentRefinement)},on:{"input":[function($event){if($event.target.composing){ return; }currentRefinement=$event.target.value},function($event){return refine($event.currentTarget.value)}]}}),_c('span',{attrs:{"hidden":!isSearchStalled}},[_vm._v("Loading...")])])}}],null,true)}):_vm._e()],1)]),(_vm.$route.query.legal_name)?_c('div',{staticClass:"group legal-name__container"},[_c('label',{staticClass:"inline"},[_c('strong',[_vm._v("Legal Name:")])]),_vm._v(" "+_vm._s(_vm.$route.query.legal_name)+" ")]):_vm._e(),_c('div',{staticClass:"group"},[_c('label',{staticClass:"titles-headline__container"},[_c('strong',[_vm._v("Titles")]),_vm._v(" ("),_c('span',[_vm._v(_vm._s(_vm.getTitlesCount(searchState)))]),_vm._v("):")]),_c('div',{staticClass:"mobile-filter__container"},[_c('div',{staticClass:"mobile-filter__content"},_vm._l((_vm.$options.mobileFilterOptions),function(filter){return _c('div',{key:filter},[_c('a',{staticClass:"mobile-filter-category",class:{ active: filter === _vm.mobileFilterCategory },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.setMobileFilterCategory(filter)}}},[_vm._v(" "+_vm._s(filter)+" ")])])}),0)])]),_c('div',[_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refineNext = ref.refineNext;
var isLastPage = ref.isLastPage;
return _c('div',{staticClass:"search-results"},[_c('div',{staticClass:"search-results__content"},[_c('ul',{staticClass:"title-hits"},[_vm._l((items),function(item){return _c('li',{key:item.objectID},[_c('router-link',{staticClass:"title-link",attrs:{"to":'/details/' + item.id}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('LoadMore',{on:{"loadMore":function($event){!isLastPage && refineNext()}}})],2)])])}}],null,true)})],1),(_vm.$route.query.legal_name)?_c('div',{staticClass:"mobile-artist-name__container"},[_c('div',{staticClass:"mobile-artist-name__content"},[_c('label',{staticClass:"mobile-artist-name__label"},[_vm._v("Name: ")]),_c('span',{staticClass:"mobile-artist-name"},[_vm._v(_vm._s(_vm.$route.query.legal_name))])])]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }